<template>
  <Transition name="slide-bottom">
    <div v-if="value" :class="['ui-notification', { error: error }]">{{ message }}</div>
  </Transition>
</template>

<script>
export default {
  name: "UiNotification",

  props: {
    value: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: "Успешно удален подтип"
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value() {
      if(this.value) {
        setTimeout(() => {
          this.$emit('input', false)
        }, 3000)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .ui-notification {
    position: fixed;
    right: 50px;
    top: 100px;
    z-index: 100000;
    max-width: 400px;
    padding: 15px 20px;
    background: #00B712;
    color: #FFFFFF;
    border-radius: 10px;
    transition: .5s;
    text-align: center;

    &.error {
      background: #E21F1F;
    }
  }
</style>