<template>
  <div class="user-informer">
    <div class="user-informer__select">
      <div class="user-informer__select-item">
        <p>{{ $t('incident-type') }}</p>
        <ui-select @input="changeTypes" :options="getTypes" v-model="incidentType" />
      </div>
      <div class="user-informer__select-item">
        <p>{{ $t('subtype') }}</p>
        <ui-select @input="changeSubtype" :options="getSubtypes" v-model="subtype" />
      </div>
    </div>
    <Loader v-model="loader" />
    <UiNotification v-model="showNotification" :message="message" />
    <Table
        v-if="newTable && subtype.name && !loader"
        :descriptionList="[{title: $t('title'), width: 440}]"
        :actionText="$t('add-variable')"
        @addTitle="addTitle($event)"
    />
    <template v-if="!loader && subtype.name">
      <Table
          v-for="(item, key) in getSpecsTypes"
          :key="key"
          :title="item.name"
          :item="item"
          :descriptionList="[{title: $t('title'), width: 440}]"
          :actionText="$t('add-variable')"
          @addRowField="addRowFieldValues($event)"
          @deleteRowField="deleteRowFieldValues($event)"
          @changeRowField="changeRowFieldValues($event)"
          @updateTitle="updateTitle($event)"
          ref="table"
          newTable
      />
    </template>
    <div class="user-informer__action">
      <ui-button v-if="subtype.name && !loader" @click="addNewTable" color="error">
        <img src="@/assets/svg/icon-plus-white.svg" alt="">
        {{ $t('new-table') }}
      </ui-button>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import UiNotification from "@/components/ui/UiNotification";

export default {
  name: "UserInformer",
  components: {
    UiNotification,
    UiButton: () => import('@/components/ui/UiButton'),
    UiSelect: () => import('@/components/ui/UiSelect'),
    Table: () => import('@/components/ui/UiTable'),
    Loader: () => import('@/components/ui/UiLoader')
  },

  data() {
    return {
      incidentType: { name: '', value: '' },
      subtype: { name: '', value: '' },
      loader: false,
      newTable: false,
      message: '',
      showNotification: false
    }
  },

  computed: {
    ...mapGetters(['getTypes', 'getSubtypes', 'getSpecsTypes']),
  },
  methods: {
    ...mapActions(['types', 'subtypes', 'specsTypes', 'addVariable', 'addVariableValues', 'deleteVariableValues', 'editVariableValues', 'editVariable']),
    getSpecTypes(message) {
      let payload = {
        orderTypeId: this.incidentType.id,
        orderSubtypeId: this.subtype.id,
        specGroup: 'Dictionary'
      };
      this.specsTypes(payload)
          .then(() => {
            setTimeout(() => {
              this.loader = false
              this.showNotification = true
              this.message = message
            }, 500)
          })
    },
    changeTypes() {
      this.subtype = {}
      this.newTable = false
      this.subtypes(this.incidentType.id)
    },
    changeSubtype() {
      this.newTable = false
      this.loader = true;

      let payload = {
        orderTypeId: this.incidentType.id,
        orderSubtypeId: this.subtype.id,
        specGroup: 'Dictionary'
      };

      this.specsTypes(payload).then(() => {
        setTimeout(() => {
          this.loader = false
        }, 500);
      })
    },
    addNewTable () {
      this.newTable = !this.newTable
    },
    addTitle(data) {
      this.loader = true;
      this.newTable = false;

      this.addVariable({
        name: data,
        orderTypeId: +this.incidentType.id,
        orderSubtypeId: +this.subtype.id,
        dataType: 'Key',
        specGroup: "Dictionary"
      }).then(() => {
        this.getSpecTypes(this.$t('variable-successfully-added'))
      })
    },
    updateTitle(data) {
      this.loader = true;
      this.editVariable({
        name: data.value,
        id: +data.id,
        orderTypeId: +this.incidentType.id,
        orderSubtypeId: +this.subtype.id,
        dataType: "Key",
        specGroup: "Dictionary"
      })
      .then(() => {
        this.getSpecTypes(this.$t('variable-successfully-updated'))
      })
    },
    addRowFieldValues (data) {
      this.loader = true;

      let payload = {
        id: data.typeId,
        name: data.name
      };

      this.addVariableValues(payload).then(() => {
        this.getSpecTypes(this.$t('variable-successfully-added'))
      })
    },

    deleteRowFieldValues (data) {
      this.loader = true;

      let payload = {
        id: this.incidentType.id,
        value: data.id,
        name: data.name
      };

      this.deleteVariableValues(payload).then(() => {
        this.getSpecTypes(this.$t('variable-successfully-deleted'))
      })
    },
    changeRowFieldValues(data) {
      this.loader = true;

      let payload = {
        id: this.incidentType.id,
        value: data.id,
        name: data.name
      };

      this.editVariableValues(payload).then(() => {
        this.getSpecTypes(this.$t('variable-successfully-updated'))
      })
    },
  }
}
</script>

<style lang="scss" scoped>
 .user-informer {
   display: flex;
   flex-direction: column;
   gap: 30px;
   width: 100%;

   &__select {
     display: flex;
     flex-direction: column;
     padding: 20px;
     gap: 20px;
     background: #FFFFFF;
     box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
     border-radius: 20px;
     max-width: 490px;
     width: 100%;

     ::v-deep .ui-select {
       z-index: auto;
       max-width: 305px;
     }
     ::v-deep .ui-select__label {
       display: none;
     }

     &-item {
       display: flex;
       align-items: center;
       justify-content: space-between;
       gap: 20px;

       p {
         font-size: 16px;
         line-height: 19px;
         color: #1B1A1F;
         white-space: nowrap;
       }
     }
   }

   &__action {

     button {
       max-width: 366px;
     }
   }
 }
</style>